import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout, Header, Drawer } from 'react-mdl';
import AppNavigation from './components/AppNavigation'
import SignInPage from './components/SignInPage';
import LandingPage from './components/LandingPage';
import NoMatchPage from './components/NoMatchPage';
import * as ROUTES from './constants/routes';


class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Layout className="App" >
                        <Header transparent
                            title="Saltsoft"
                            className="Header">
                            <AppNavigation />
                        </Header>
                        <main>
                            <Switch>
                                <Route exact path={ROUTES.LANDING} component={LandingPage} />
                                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                                <Route component={NoMatchPage} />
                            </Switch>
                        </main>
                        <Drawer title="Saltsoft">
                            <AppNavigation />
                        </Drawer>
                    </Layout>
                </div>
            </Router>
        );
    }
}

export default App;
