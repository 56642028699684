import React, { Component } from 'react';

class SignInPage extends Component {
    render() {
        return (
            <div className="AppCaption">
                <span>Sign in will be avaliable in few days</span>
            </div>
        );
    }
}

export default SignInPage;
