import React, { Component } from 'react';

class NoMatchPage extends Component {
    render() {
        return (
            <div className="AppCaption">
                <span>Page Not Found (404)</span>
            </div>
        );
    }
}

export default NoMatchPage;
