import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from 'react-mdl';
import * as ROUTES from '../constants/routes'

class AppNavigation extends Component {
    render() {
        return (
            <Navigation>
                <Link to={ROUTES.SIGN_IN}>Sign In</Link>
                <Link to={ROUTES.LANDING}>
                    <i className="material-icons">home</i>
                </Link>
            </Navigation>
        );
    }
}

export default AppNavigation;
