import React, { Component } from 'react';

class LandingPage extends Component {
    render() {
        return (
            <div className="AppCaption">
                <span>Quality is the salt of the software</span>
            </div>
        );
    }
}

export default LandingPage;
